import { Power1 } from 'gsap';

export function animateValuescards(tl, element) {
  return tl.staggerFrom(
    element,
    0.5,
    {
      opacity: 0,
      y: 10,
      ease: Power1.easeOut,
    },
    0,
    1
  );
}

export function animateBottomText(tl, element) {
  return tl.staggerFrom(
    element,
    0.6,
    {
      opacity: 0,
      y: 40,
      ease: Power1.easeOut,
    },
    0,
    0.3
  );
}
