import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { TimelineLite } from 'gsap';
import { Link, graphql } from 'gatsby';
import Observer from 'react-intersection-observer';
import Img from 'gatsby-image';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import '../sass/_ourmission.scss';
import ReadMore from '../../static/assets/Readmore.svg';
import Bluekey from '../../static/assets/blue_key.svg';
import * as globalAnimation from '../animations/globalAnimation';
import * as MissionAnimate from '../animations/ourMission';

export default class ourMission extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    globalAnimation.animateImageRight(
      this.tl,
      '.svg-line__line_blue',
      '.svg_circle_blue',
      '.mission-image',
      '.orange_bg',
      '#title',
      '#desc',
      '#btn_anim'
    );
  }

  valuesAnimation = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.animateHeading(tl, '.second-title', '.second-subtitle');
      MissionAnimate.animateValuescards(tl, '.values_card');
    }
  };

  animateBottomSection = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      MissionAnimate.animateBottomText(tl, '.bottom-section');
    }
  };

  render() {
    const {
      data: {
        markdownRemark: {
          frontmatter: {
            title,
            pOne,
            pTwo,
            pThree,
            image,
            values,
            values: { cards },
          },
        },
      },
    } = this.props;
    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="Our Mission" />
        <Container fluid className="main_wrapper section-one">
          <Row>
            <Col>
              <div className="wrapper">
                <div className="page_heading_section text-center">
                  <h3 className="page_heading text-uppercase d-lg-block d-none title">{title}</h3>
                  <h4 className="text-uppercase page_heading_child subtitle">{title}</h4>
                </div>
              </div>
            </Col>
          </Row>
          <Row noGutters className="py-lg-5 py-3">
            <Col xs={12} className="d-lg-none">
              <div>
                <Img
                  className="img-fluid cover_pic square mx-auto"
                  fluid={image.childImageSharp.fluid}
                />
              </div>
            </Col>

            <Col xs={12} lg={4} className="d-none d-lg-block">
              <div className="ourstory_imgbox orange_bg">
                <div className="bg-orange square mx-auto my-md-4 my-lg-0">
                  <div className="">
                    <Img
                      className="img-fluid mission-image cover_pic square"
                      fluid={image.childImageSharp.fluid}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={{ span: 6, offset: 1 }}>
              <div className="position-relative line line-horizontal px-2 py-3 py-lg-0">
                <Bluekey />
                <p id="desc">{pOne}</p>
                <p id="desc">{pTwo}</p>
                <p id="desc">{pThree}</p>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <div className="learn_more">
                    <Link to="pro-bono/" id="desc" className=" bordered-btn text-center">
                      Read more{' '}
                      <ReadMore className="arrow-translate mb-auto" height="10px" width="40px" />
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="bg-whitesmoke">
          <Container className="section-two">
            <Row>
              <Col>
                <Observer onChange={this.valuesAnimation} triggerOnce>
                  <div className="wrapper">
                    <div className="page_heading_section text-center">
                      <h3 className="page_heading text-uppercase d-lg-block d-none second-title">
                        {values.title}
                      </h3>
                      <h4 className="text-uppercase page_heading_child second-subtitle">
                        {values.title}
                      </h4>
                    </div>
                  </div>
                </Observer>
              </Col>
            </Row>
            <Row className="pb-0 pb-lg-5">
              {cards.map((card, index) => (
                <Col xs={12} lg={3} className="py-2" key={String(index)}>
                  <Observer onChange={this.valuesCardAnimation} triggerOnce>
                    <div className="values_card position-relative">
                      <Img
                        className="d-block img-fluid "
                        fluid={card.image.childImageSharp.fluid}
                      />
                      <div className="overlay d-flex flex-column justify-content-center">
                        <h5 className="text-white mb-0 align-self-center mission_overlay text-semibold">
                          {card.title}
                        </h5>
                        <div className="align-self-center value_text flex-column">
                          <div className="line my-2 text-center align-self-center" />
                          <div className="text-white my-2 text-center px-3">
                            <h6 className="font-weight-light">{card.description}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Observer>
                </Col>
              ))}
              <Col xs={12}>
                <div className="my-3 pt-lg-5">
                  <div className="px-0">
                    <Observer onChange={this.animateBottomSection} triggerOnce>
                      <div className="page_heading_section text-center bottom-section">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <div>
                            <h5 className="text-bold text-appcolor">
                              Do you share our values? Learn more about what it&apos;s like to work
                              at BluePrint.
                            </h5>
                          </div>
                          <div className="d-flex justify-content-center pl-lg-4">
                            <div className="py-3 mission-arrow">
                              <Link
                                to="/what-is-the-bluePrint-culture-like/"
                                className="bordered-btn text-center d-flex align-items-center"
                              >
                                Read about our culture{' '}
                                <ReadMore
                                  className="arrow-translate mb-0"
                                  height="10px"
                                  width="40px"
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Observer>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "our-mission" } }) {
      frontmatter {
        title
        pOne
        pTwo
        pThree
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        values {
          title
          cards {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
